<template>
  <div class="px-5">
    <div class="fs-24 fw-500 text-black my-3">
      <span>{{ form.name }}</span>
    </div>
    <div class="bg-white px-4 rounded-2xl mt-2 pb-10">
      <div class="d-flex justify-content-end mb-3 pt-3">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="cursor-pointer"
          @click="handleBackSubOrgList"
        >
          <path
            d="M6.5293 17.9998L18.5291 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.5291 17.9998L6.5293 6"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div>
        <div class="grid grid-cols-3 gap-4">
          <div>
            <form class="flex flex-col items-center justify-center">
              <el-skeleton class="mb-3 mt-4" style="width: 288px">
                <template slot="template">
                  <img
                    v-if="thumb_file"
                    class="w-72 h-64 object-contain"
                    :src="thumb_file"
                    alt="Logo org"
                  />
                  <el-skeleton-item v-else variant="image" style="width: 288px; height: 256px" />
                </template>
              </el-skeleton>

              <label for="file-upload">
                <div
                  class="button-add text-white cursor-pointer flex items-center justify-center rounded-lg h-10 px-3 py-2 focus:outline-none"
                >
                  <div>{{ $t('lbl_change_logo') }}</div>
                </div>
              </label>
              <input
                id="file-upload"
                class="d-none"
                name="upload_cont_img"
                type="file"
                accept="image/*"
                @change="onSelectedThumbFile($event)"
              />
            </form>
          </div>
          <div class="col-span-2 m-4">
            <div class="grid grid-cols-2 gap-4 mb-3">
              <div>
                <label
                  class="text-xl text-black font-medium ml-3 mb-2"
                >{{ $t('lbl_governing_body') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_governing_body')"
                  v-model="form.authority_in_charge"
                />
              </div>
              <div>
                <label
                  class="text-xl text-black font-medium ml-3 mb-2 required"
                >{{ $t('workspace_setting.lbl_org_name') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_name')"
                  v-model="form.name"
                />
              </div>
            </div>
            <div class="grid grid-cols-2 gap-4 mb-3">
              <div>
                <label class="text-xl text-black font-medium ml-3 mb-2">{{ $t('lbl_hotline') }}</label>
                <input
                  class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                  :placeholder="$t('lbl_enter_phone_number')"
                  v-model="form.contact_point"
                />
              </div>
              <div>
                <label
                  class="text-xl text-black font-medium ml-3 mb-2"
                >{{ $t('lbl_director_president_name') }}</label>
                <div
                  class="border-0 flex items-center text--dark rounded-xl text-xl h-12 cursor-not-allowed bg-blue-gray pl-3 focus:outline-none w-full"
                >{{ owner.name }}</div>
              </div>
            </div>

            <div class="mb-3">
              <label
                class="text-xl text-black font-medium ml-3 mb-2 required"
              >{{ $t('lbl_address') }}</label>
              <input
                class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 focus:outline-none w-full"
                :placeholder="$t('lbl_enter_address')"
                v-model="form.address"
              />
            </div>

            <div>
              <label
                class="text-xl text-black font-medium ml-3 mb-2 required"
              >{{ $t('lbl_organization_model') }}</label>
              <select
                id="org-model"
                name="org-model"
                class="border-0 text--dark rounded-xl text-xl h-12 bg-blue-gray pl-3 pr-3 focus:outline-none w-full cursor-pointer"
                v-model="form.is_medical"
              >
                <option
                  :value="1"
                  :selected="form.is_medical === 1"
                >{{ $t('lbl_applicable_to_hospital') }}</option>
                <option
                  :value="2"
                  :selected="form.is_medical === 2"
                >{{ $t('lbl_applicable_to_businesses') }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="text-right mx-4">
          <button
            v-loading="isFetching"
            @click="handleSubmitSubOrg"
            class="button-add text-white rounded-lg h-10 w-24 px-3 py-2 border-0 focus:outline-none"
          >{{ $t('lbl_update') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import appUtils from '../../utils/appUtils'
import { isNil } from 'lodash'

export default {
  name: 'OrganizationDetailUpdate',
  data () {
    return {
      appUtils,
      org: {},
      thumb_file: '',
      listOption: null,
      imgPut: '',
      form: {},
      orgId: 0,
      isFetching: false,
      owner: {},
      subOrgData: {}
    }
  },
  mounted () {
    this.orgId = this.$user.orgId
    this.getOrg(this.orgId)
    this.getSubOrgDetail()
    this.getOrgMembers()
  },
  methods: {
    async getOrg (id) {
      if (!id) return
      const response = await this.$rf.getRequest('DoctorRequest').getOrg(id)

      this.org = response.data
    },
    async getSubOrgDetail () {
      try {
        const subOrgId = this.$route.params.id
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOrg(subOrgId)
        const organizationData = response.data || {}
        this.form = { ...organizationData }
        this.thumb_file = organizationData?.logo
          ? appUtils.getImageURL(organizationData?.logo)
          : ''
        this.owner = organizationData?.owner || {}
        this.subOrgData = organizationData
      } catch (error) {
        console.log(error)
      }
    },
    onSelectedThumbFile (event) {
      this.imgPut = event.target.files[0]
      this.thumb_file = URL.createObjectURL(event.target.files[0])
    },
    async handlePostSubOrg () {
      const self = this
      self.isFetching = true

      try {
        this.$orgModel =
          self.form.is_medical === 1 ? 'hospital' : 'business_org'
        appUtils.setGlobalOrg({
          ...this.$globalOrg,
          is_medical: self.form.is_medical
        })
        const subOrgId = self.$route.params.id
        const params = {
          authority_in_charge: self.form.authority_in_charge,
          name: self.form.name,
          address: self.form.address,
          contact_point: self.form.contact_point,
          parent_id: self.org.id,
          is_medical: self.form.is_medical
        }
        if (self.imgPut) {
          params.logo = self.imgPut
        }
        const formData = new FormData()

        for (const key in params) {
          if (!isNil(params[key])) {
            formData.set(key, params[key])
          }
        }

        console.log(params)

        await self.$rf
          .getRequest('DoctorRequest')
          .postUpdateSubOrganization(subOrgId, formData)

        this.$toast.open({
          message: this.$t('lbl_update_success'),
          type: 'success'
        })
        this.getSubOrgDetail()
      } catch (error) {
        console.log(error)
        self.$toast.open({
          message: this.$t('lbl_udpate_fail'),
          type: 'warning'
        })
      } finally {
        this.isFetching = false
      }
    },
    handleSubmitSubOrg () {
      if (!this.form.name || !this.form.address || !this.form.is_medical) {
        return
      }
      //   if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
      //     alert('Bạn không có quyền cập nhật thông tin')
      //     return
      //   }

      this.handlePostSubOrg()
    },
    backOrg () {
      this.$router.push({
        path: '/'
      })
    },
    handleBackSubOrgList () {
      this.$router.go(-1)
      // const self = this
      // console.log(self.subOrgData)
      // if (isNaN(self.subOrgData?.parent_id)) {
      //   self.$router.push({
      //     name: 'OrganizationManagement'
      //   })
      // } else {
      //   self.$router.push({
      //     name: 'OrganizationDetail',
      //     params: { id: self.$route.params.id }
      //   })
      // }
    },
    async getOrgMembers () {
      try {
        const params = {
          org_id: this.$user?.members[0].org_id,
          status: 1,
          page: 1,
          limit: 200
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOrgMembers(params)

        this.dataOrgMember = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .button-add {
    background-color: #20419b;
  }
  .select-form {
    appearance: none;
  }

  .arrow-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .el-select-owner {
    input {
      font-size: 18px !important;
      color: #41464b !important;
      font-weight: 400 !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    input::placeholder {
      font-size: 20px;
      color: #868181;
      font-weight: 400;
    }
  }

  .txt-grey-1000 {
    color: #52575c !important;
  }
  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
  option {
    display: block;
  }
}
</style>
